import React, { useEffect, useState } from 'react';
import Headers from '../components/Header';
import { FaCheckCircle } from 'react-icons/fa'; // Importing checkmark icon from react-icons
import NumberFlow from '@number-flow/react'; // Use the default export
import LogoSection from './LogoSection';
import Footer from './Footer'
import Weinclude from '../components/Weinclude'
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const Subscription = () => {

  const [plans, setPlans] = useState([]); // State to store subscription plans
  const [isLoading, setIsLoading] = useState(true); // State to handle loading
  const [error, setError] = useState(null); // State to handle errors
  const navigate = useNavigate();


  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch('https://verse.blaccdot.com/ldsbackend/masters/subscription/all');
        if (!response.ok) {
          throw new Error('Failed to fetch plans');
        }
        const data = await response.json();
        const formattedPlans = data.data.subscriptionmasters.map((plan, index) => ({
          id: plan._id,
          subscriptionmasterId:plan.subscriptionmasterId,
          title: plan.title,
          monthlyPrice: plan.monthAmount,
          annualPrice: plan.anualAmount,
          taxAmount: plan.taxAmount,
           description: `${plan.noofLeads}`,
          features: Object.values(plan.textcontent), // Convert `textcontent` object to an array
          highlighted: index === 1, // Highlight the first plan by default (change logic if needed)
        }));       
        setPlans(formattedPlans);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPlans();
  }, []);

  const handleRediract = (plan) => {
    sessionStorage.setItem("subscriptionID", plan.subscriptionmasterId); 
    sessionStorage.setItem('planTitle', plan.title)
    sessionStorage.setItem('planAmount', plan.monthlyPrice)
    sessionStorage.setItem('plantax', plan.taxAmount) 
    sessionStorage.setItem('planTitle', plan.title)
    sessionStorage.setItem('credit', plan.description);   
    navigate('/signup');
  };
  const handleCustomFetch = async ()=>{
    const formID = sessionStorage.getItem('finalFormID')
    const payload = {
      user_type: "custom"
    }
    try {
      const response = await fetch(`https://euctostaging.com/lms/api/businessinitialform/update/${formID}`,{
        method:'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      const result = await response.json()
      navigate('/appointment');
    } catch (error) {
      console.log('error', error)
    }
  }

  // const handleCustomFetch = ()=>{
  //   navigate('/appointment');
  // }
 

  return (
    <>
      <Headers />
      <div className="pricing-container">
        <h1 className="pricing-title">Plans That Fit Your Business</h1>
        <p className="pricing-subtitle">
          From startups to enterprises, find the right solution to boost your business growth.
        </p>

        {/* Mapping through the plans array to render pricing cards dynamically */}
        <div className="pricing-cards">
        {plans.length > 0
            ? plans.map((plan) => (
                <div
                key={plan.subscriptionmasterId}
                  className={`pricing-card ${plan.highlighted ? "highlighted" : ""}`}>
                  <div className="plan-header">
                    <h2>{plan.title}</h2>
                    <p className="price">
                      <NumberFlow
                        value={plan.monthlyPrice}
                        duration={1000}
                        format="currency"/>
                      <span className="billing-info">+ Taxes</span>
                    </p>
                    <p className="description">{plan.description} credit included</p>
                  </div>
                  <ul className="feature-list">
                    {plan.features.map((feature, idx) => (
                      <li key={idx}>
                        <FaCheckCircle className="icon"/> {feature}
                      </li>
                    ))}
                  </ul>
                  <button onClick={() => handleRediract(plan)} className={`choose-plan-button ${plan.highlighted ? "highlighted-button" : ""}`}>
                    Choose {plan.title}</button>

                </div>
              ))  : !isLoading && <p>No plans available</p>}
         {/* Custom */}
            <div className={`pricing-card`}>
              <div className="plan-header">
                <h2>Custom</h2>
                <p className="pricecustom">
                  <span>Make Your Own</span>
                  <span className="billing-info mt-2">Tailored to your needs</span>
                </p>
                <p className="tax-info">+ Taxes</p>
              </div>
              <ul className="feature-list">
                  <li>
                    <FaCheckCircle className="icon" /> All Pro Plan Features Plus
                  </li>
                  <li>
                    <FaCheckCircle className="icon" /> All Pro Plan Features Plus
                  </li>
                  <li>
                    <FaCheckCircle className="icon" /> All Pro Plan Features Plus
                  </li>
                  <li>
                    <FaCheckCircle className="icon" /> All Pro Plan Features Plus
                  </li>
                  <li>
                    <FaCheckCircle className="icon" /> All Pro Plan Features Plus
                  </li>
                  <li>
                    <FaCheckCircle className="icon" /> All Pro Plan Features Plus
                  </li>
                  <li>
                    <FaCheckCircle className="icon" /> All Pro Plan Features Plus
                  </li>
                  <li>
                    <FaCheckCircle className="icon" /> All Pro Plan Features Plus eatures Plus
                  </li>
                  <li>
                    <FaCheckCircle className="icon" /> All Pro Plan Features Plus
                  </li>
                  <li>
                    <FaCheckCircle className="icon" /> All Pro Plan Features Plus
                  </li>
              </ul>
             <button onClick={handleCustomFetch} className="choose-plan-button">Choose Custom</button>
            </div>
        </div>


      </div>
      <>
        <LogoSection/>
      </>
      <>
        <Weinclude/>
      </>
      <>
        <Footer/>
      </>
   
    </>
  );
};

export default Subscription;




// import React, { useState } from 'react';
// import Headers from '../components/Header';
// import { FaCheckCircle } from 'react-icons/fa'; // Importing checkmark icon from react-icons
// import NumberFlow from '@number-flow/react'; // Use the default export
// import LogoSection from './LogoSection';
// import Footer from './Footer'
// import Weinclude from '../components/Weinclude'
// import { useNavigate } from 'react-router-dom';

// const Subscription = () => {

//   const navigate = useNavigate()

//   const plans = [
//     {
//       title: 'Hybrid',
//       monthlyPrice: '₹2,917',
//       annualPrice: '₹7,250', // annual price for Hybrid
//       billingInfo: 'Per Month (Billed annually)',
//       description: 'Perfect for small businesses just starting out.',
//       features: [
//         '2.9% Revenue Sharing',
//         'All Pro Plan Features Plus',
//         'Dedicated Account Manager',
//         'Custom API Integrations',
//         'Onboarding and Training Support',
//         'Multi-Language Support',
//         'Advanced Reporting',
//         'Customizable Workflows',
//         'Custom API Integrations',
//         'Email Support',
//       ],
//       buttonText: 'Choose Hybrid',
//     },
//     {
//       title: 'Pro',
//       monthlyPrice: '₹7,999',
//       annualPrice: '₹15,450', // annual price for Pro
//       billingInfo: 'Per Month (Billed annually)',
//       description: 'Ideal for growing businesses needing more advanced features.',
//       features: [
//         'All Hybrid Plan Features Plus',
//         'Advanced Reporting',
//         'Customizable Workflows',
//         'Integration with Third-Party Apps',
//         'Priority Email Support',
//         'Custom API Integrations',
//         'Onboarding and Training Support',
//         'Multi-Language Support',
//         'Email Support',
//       ],
//       buttonText: 'Choose Pro',
//       highlighted: true, // To style differently if needed
//     },
//     {
//       title: 'Premium',
//       monthlyPrice: '₹14,999',
//       annualPrice: '₹21,600', // annual price for Premium
//       billingInfo: 'Per Month (Billed annually)',
//       description: 'Designed for large organizations requiring comprehensive solutions.',
//       features: [
//         'All Pro Plan Features Plus',
//         'Dedicated Account Manager',
//         'Custom API Integrations',
//         'Onboarding and Training Support',
//         'Multi-Language Support',
//         'Advanced Reporting',
//         'Customizable Workflows',
//         'Custom API Integrations',
//         'Integration with Third-Party Apps',
//       ],
//       buttonText: 'Choose Premium',
//     },
//   ];

//   const [isAnnually, setIsAnnually] = useState(false);

//   const togglePricing = () => {
//     setIsAnnually((prev) => !prev);
//   };

//   // Function to remove currency symbol and convert string to number
//   const getPriceValue = (price) => {
//     return parseFloat(price.replace('₹', '').replace(',', '')); // Removes ₹ and commas, then converts to number
//   };

//   // Display the correct price based on whether it's annual or monthly
//   const updatedPlans = plans.map((plan) => ({
//     ...plan,
//     price: isAnnually ? plan.annualPrice : plan.monthlyPrice,
//   }));

//   const handleRediract = ()=>{
//     navigate('/signup')
//   }

//   return (
//     <>
//       <Headers />
//       <div className="pricing-container">
//         <h1 className="pricing-title">Plans That Fit Your Business</h1>
//         <p className="pricing-subtitle">
//           From startups to enterprises, find the right solution to boost your business growth.
//         </p>
//         <div className="pricing-toggle">
//           <button className={`toggle-btn ${!isAnnually ? 'active' : ''}`} onClick={togglePricing}>
//             Monthly
//           </button>
//           <button className={`toggle-btn ${isAnnually ? 'active' : ''}`} onClick={togglePricing}>
//             Annually
//           </button>
//         </div>

//         {/* Mapping through the plans array to render pricing cards dynamically */}
//         <div className="pricing-cards">
//           {updatedPlans.map((plan, index) => (
//             <div
//               key={index}
//               className={`pricing-card ${plan.highlighted ? 'highlighted' : ''}`}>
//               <div className="plan-header">
//                 <h2>{plan.title}</h2>
//                 <p className="price">
//                   {/* Display the price with NumberFlow */}
//                   <NumberFlow
//                     value={isAnnually ? getPriceValue(plan.annualPrice) : getPriceValue(plan.monthlyPrice)}
//                     duration={1000}
//                     format="currency"
//                   />
//                   <span className="billing-info">{plan.billingInfo}</span>
//                 </p>
//                 <p className="tax-info">+ Taxes</p>
//               </div>
//               <ul className="feature-list">
//                 {plan.features.map((feature, idx) => (
//                   <li key={idx}>
//                     <FaCheckCircle className="icon" /> {feature}
//                   </li>
//                 ))}
//               </ul>
//               <button onClick={handleRediract} className="choose-plan-button">{plan.buttonText}</button>
//             </div>
//           ))}
//         </div>



//       </div>

//       <>
//         <LogoSection/>
//       </>
//       <>
//         <Weinclude/>
//       </>
//       <>
//         <Footer/>
//       </>
   
//     </>
//   );
// };

// export default Subscription;





