import React, { useState } from 'react';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import Header from '../components/Header';
import { Row, Col } from 'react-bootstrap';
import Logo from '../Images/blaccloo.png';
import { IoIosTime } from 'react-icons/io';
import { GoDotFill } from 'react-icons/go';
import { IoCall } from 'react-icons/io5';
import { FcAlarmClock } from 'react-icons/fc';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Fade } from 'react-reveal';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';

const Appointment = () => {
  const navigate = useNavigate();
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date();
  const formID = sessionStorage.getItem('finalFormID');

  const handleBoxClick = (boxId) => {
    setSelectedBox(boxId);
  };

  const handleScheduleEvent = async () => {
    try {
      if (selectedDay && selectedBox) {
        setIsLoading(true);

        // Format the date to "YYYY-MM-DD"
        const day = selectedDay.getDate().toString().padStart(2, '0');
        const month = (selectedDay.getMonth() + 1).toString().padStart(2, '0');
        const year = selectedDay.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;

        // Convert the selectedBox time to "HH:mm:ss" format
        const timeParts = selectedBox.split(' ')[0].split(':');
        let hours = parseInt(timeParts[0], 10);
        const minutes = timeParts[1];
        if (selectedBox.includes('PM') && hours !== 12) {
          hours += 12;
        } else if (selectedBox.includes('AM') && hours === 12) {
          hours = 0;
        }
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes}:00`;

        // Prepare JSON data
        const jsonData = {
          appointment_date: formattedDate,
          appointment_time: formattedTime,
        };

        console.log('JSON Payload being sent:', jsonData);

        // Send the PUT request
        const response = await fetch(`https://euctostaging.com/lms/api/businessinitialform/updateAppointment/${formID}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonData),
        });

        const responseData = await response.json();
        console.log('Response Data:', responseData);

        if (response.ok) {
          console.log('Appointment scheduled successfully');
          navigate('/thankyou');
        } else {
          console.error('Failed to schedule appointment:', responseData.message || response.statusText);
        }
      } else {
        alert('Please select both a date and time for scheduling');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      alert('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const boxStyle = (time) => ({
    border: '1px solid #D8D4D1',
    borderRadius: '10px',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: selectedBox === time ? 'blue' : '',
    color: selectedBox === time ? '#ffff' : '',
  });

  return (
    <div>
      <Header />
      <div className='container my-3'>
        <Fade right>
          <div style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: '10px' }}>
            <Row>
              {/* Left Panel */}
              <Col xs={12} md={4}>
                <div className='mx-4'>
                  <img src={Logo} alt='logo' style={{ width: '50%', marginTop: '35px', textAlign: 'center' }} />
                  <hr />
                  <h3 style={{ fontFamily: 'sans-serif', fontWeight: '700' }}>Lead Requirements & Discussion</h3>
                  <div style={{ display: 'flex', marginTop: '10px' }}>
                    <IoIosTime size={25} style={{ color: '#737373' }} />
                    <p className='ms-1'>30 min</p>
                  </div>
                  <h6 style={{ fontFamily: 'sans-serif', fontWeight: '700' }}>
                    <IoCall className='text-danger me-1' />
                    The purpose of this call is:
                  </h6>
                  <p><GoDotFill /> Learning about the Lead requirements and initiating a discussion.</p>
                  <p><GoDotFill /> An overview of our team, our capabilities, and the processes.</p>
                  <hr />
                  <h6 style={{ fontFamily: 'sans-serif', fontWeight: '700' }}>
                    <FcAlarmClock className='text-danger me-1' />
                    The times are in Indian time:
                  </h6>
                  <p><GoDotFill /> If you need a different time, please email us at <a href="mailto:support@blaccdot.com">support@blaccdot.com</a></p>
                </div>
              </Col>

              {/* Calendar Selection */}
              <Col xs={12} md={5}>
                <div style={{ marginTop: '10%' }}>
                  <h4 style={{ fontFamily: 'sans-serif', fontWeight: '700' }}>Select a Date & Time</h4>
                </div>
                <div className="card flex justify-content-center">
                  <Calendar
                    value={selectedDay}
                    onChange={(e) => setSelectedDay(e.value)}
                    inline
                    minDate={today}
                  />
                </div>
              </Col>

              {/* Time Slots */}
              <Col xs={12} md={3}>
                <div className='mx-4' style={{ marginTop: '40px' }}>
                  <span style={{ fontSize: 'small' }}>Scroll down to view more timing</span>
                  <div style={{ maxHeight: '400px', overflowY: 'auto', marginTop: '10px' }}>
                    {[
                      '10:00 AM - 10:30 AM',
                      '11:00 AM - 11:30 AM',
                      '11:30 AM - 12:00 PM',
                      '12:00 PM - 12:30 PM',
                      '12:30 PM - 01:00 PM',
                      '01:00 PM - 01:30 PM',
                      '01:30 PM - 02:00 PM',
                      '02:00 PM - 02:30 PM',
                      '02:30 PM - 03:00 PM',
                      '03:00 PM - 03:30 PM',
                      '03:30 PM - 04:00 PM',
                      '04:00 PM - 04:30 PM',
                      '04:30 PM - 05:00 PM',
                      '05:00 PM - 05:30 PM',
                      '05:30 PM - 06:00 PM',
                      '06:00 PM - 06:30 PM',
                    ].map((time) => (
                      <div
                        key={time}
                        className='selectTime mb-3'
                        style={boxStyle(time)}
                        onClick={() => handleBoxClick(time)}
                      >
                        <p className='my-2'>{time}</p>
                      </div>
                    ))}
                  </div>
                  <div className='mb-3 text-center'>
                    <button type="submit" className='button-87' disabled={isLoading} onClick={handleScheduleEvent}>
                      {isLoading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        <>Schedule Event</>
                      )}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Appointment;
