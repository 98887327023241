import React from 'react'
import Dashboard from '../Images/Dashboard2.png'
import Header from '../components/Header';
import {Zoom} from 'react-reveal'
import HomeText from '../components/HomeText'
import Backgroung from '../Images/Backgroung.png'
import Section2 from '../Screen/Section2';
import Ourclient from '../Screen/Ourclient';
import Ourservice from '../Screen/Ourservice';
import EuctoPartner from '../Screen/EuctoPartner';
import Howweword from '../Screen/Howweword';
import Faq from '../Screen/Faq';
import Footer from '../Screen/Footer';


const Home = () => {
  return (
    <div>
      {/* className='mainBanner' */}
      <Header />
      <div className='BannerImage'  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '450px', backgroundImage: `url(${Backgroung})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
       <HomeText/>
      <a href='/selectservices'><button className="button-36 mt-3">Get Started Now</button></a>
    </div>
    </div>
    <div className='text-center'>
       {/* <img src={Dashboard} alt='dasboardImage' style={{width:'100%',position: 'absolute',top: '80%',right: '0px',bottom: '3%',}} /> */}
       <Zoom bounce>
       <img src={Dashboard} alt='dasboardImage' style={{width:'100%'}} /></Zoom>
    </div>
    <div>
      <Section2/>
    </div>
    <div>
      <Ourservice/>
    </div>
    <div className='my-5'>
      <Ourclient/>
    </div>
    <div className='mb-5'>
      <EuctoPartner/>
    </div>
    <div>
      <Howweword/>
    </div>
    <div className='pt-5'>
      <Faq/>
    </div>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <a href="/selectservices" class="btnGetQuote" style={{padding: '23px' ,position: 'fixed', zIndex: '9999', right: '20px', bottom: '20px', width: '100px', height: '100px',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <h5 style={{fontFamily: 'Lato,sans-serif',fontWeight: '600', margin: 0 }}>GET <br/>QUOTE</h5>
  </a>
</div>
      <Footer/>
    </div>
       
  )
}

export default Home
