import React,{useState, useEffect} from 'react'
import Header from '../components/Header';

const Thankyou = () => {

  const paymentUrl = sessionStorage.getItem('paymentUrl'); 



  return (
    <div>
        <Header/>
           <div>
         <div class="success-animation">
           <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
          <div className='mt-4' style={{textAlignLast: 'center'}}>
         <h4 style={{fontFamily:'sans-serif'}}>Your subscription was successfully completed</h4>
              <h6 style={{fontSize: 'initial',color: '#5e5e5e'}}>Kindly check your email for the login credentials and further instructions</h6>      
           </div>
           <p style={{textAlign:'center', color:'green'}}>{paymentUrl}</p>
      </div>      
     </div>
    </div>
  )
}

export default Thankyou
