import React,{useState} from 'react'
import Headers from '../components/Header';
import {Link,useNavigate} from 'react-router-dom';
import {Row,Col} from 'react-bootstrap'
import {Zoom,Fade} from 'react-reveal';
import Healthservice from '../Images/Healthservice.jpg'
import RealEstateservice from '../Images/RealEstateservice.jpg'
import Hero from '../Images/hero.jpeg'
import Financialservice from '../Images/Financialservice.jpg'
import Manufacturing from '../Images/Manufacturing.jpeg'
import automobile from '../Images/automobile.jpeg'
import Ecommers from '../Images/Ecommers.jpeg'
import { IoChevronBackCircleOutline } from "react-icons/io5";

const Selectserives = () => {

  const [selectedService, setSelectedService] = useState('');
  const navigate = useNavigate();

  const handleServiceSelection = (service) => {
    sessionStorage.setItem('selectedService', service);
    setSelectedService(service);
    navigate('/selectsubserives');
  };

  const ServiceSelectionRealEstate = (service) => {
    sessionStorage.setItem('selectedService', service);
    setSelectedService(service);
    navigate('/selectrealEaste');
  };

  const ServiceFinancialService = (service) => {
    sessionStorage.setItem('selectedService', service);
    setSelectedService(service);
    navigate('/financialService');
  };

  const ServiceManufacturing = (service) => {
    sessionStorage.setItem('selectedService', service);
    setSelectedService(service);
    navigate('/manufacturing');
  };

  const ServiceAutomotive = (service) => {
    sessionStorage.setItem('selectedService', service);
    setSelectedService(service);
    navigate('/movers');
  };
  const Serviceecommers = (service) => {
    sessionStorage.setItem('selectedService', service);
    setSelectedService(service);
    navigate('/ecommers');
  };


  return (
    <div>
      <Headers/>
      <div style={{backgroundColor:'#E6EDF3',height: '85vh', width: '100vw', margin: '0',padding: '0'  }}>
      <div className="container py-3">
        <div className='formBoxsh'>
          <div className='container'>
            <Row>
            <Col xs={5} style={{ padding: '0px',flex: '0 0 auto',width: '41.66666667%' }} className="FormImages">
                <div style={{textAlign:'center'}}>
                  <img src={Hero} alt='hero' style={{ width: '95%',borderRadius: '10px',marginTop:'10px',height: '445px' }}/>
                </div>
              </Col>
              <Col xs={12} md={7} style={{ padding: '0px', position: 'relative' }}>
                <div className='mainTopic'
                  style={{
                    width: '55%',
                    height: '20%',
                    borderRadius: '0px 10px 10px 0px',
                    border: '7px solid #00A0E3',
                    marginTop: '10px',
                    fontWeight: '600',
                    backgroundColor: '#00A0E3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'}}>   
                  <Zoom right> <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif',margin:'0px',fontWeight: '600'}}>Choose your domain sensually</h5></Zoom>
                </div>
                <Link to='/'><IoChevronBackCircleOutline className='backButton' size={40} style={{position: 'absolute',right: '5%',top: '2%', color:'red'}}/></Link>
                  <div className='calenderHeight' style={{ marginTop: '25px', display: 'flex', justifyContent: 'center' }}>
                    <div className='container'>
                        <Row className='mb-4'>
                        <Col md={4} xs={6} style={{ textAlign: '-webkit-center', margin: '10px 0' }}>
                            <div onClick={() => handleServiceSelection('Health Care')} className='zoomEffect' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',     padding: '15px 0',alignItems: 'center', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',  borderRadius: '5px' }}>
                              <div style={{ height: '100px', width: '100px', backgroundColor: '#bbb', borderRadius: '50%', overflow: 'hidden' }}>
                                <img src={Healthservice} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                              </div>
                              <span style={{ marginTop: '10px', fontWeight: '700', fontFamily: 'Lato' }}>Health Care</span>
                            </div>
                        </Col>
                          <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '10px 0'}}>
                          <div onClick={() => ServiceSelectionRealEstate('Real Estate')} className='zoomEffect' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '15px 0',alignItems: 'center', boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', borderRadius:'5px'}}>
                          <div style={{ height: '100px', width: '100px', backgroundColor: '#bbb', borderRadius: '50%', overflow: 'hidden' }}>
                            <img src={RealEstateservice} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                         </div>
                           <span style={{ marginTop: '10px',fontWeight: '700',fontFamily: 'Lato' }}>Real Estate</span>
                        </div>
                          </Col>
                          <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '10px 0'}}>
                          <div onClick={() => ServiceFinancialService('Financial Service')} className='zoomEffect' style={{ display: 'flex', flexDirection: 'column', padding: '15px 0',justifyContent: 'center', alignItems: 'center', boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', borderRadius:'5px' }}>
                            <div style={{ height: '100px', width: '100px', backgroundColor: '#bbb', borderRadius: '50%', overflow: 'hidden' }}>
                                <img src={Financialservice} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div> 
                          <span style={{ marginTop: '10px',fontWeight: '700',fontFamily: 'Lato' }}>Financial Service</span>
                        </div>
                          </Col>
                          <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '10px 0'}}>
                          <div onClick={() => ServiceManufacturing('Manufacturing')}  className='zoomEffect' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '15px 0',alignItems: 'center', boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', borderRadius:'5px' }}>
                          <div style={{ height: '100px', width: '100px', backgroundColor: '#bbb', borderRadius: '50%', overflow: 'hidden' }}>
                                <img src={Manufacturing} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div> 
                           <span style={{ marginTop: '10px',fontWeight: '700',fontFamily: 'Lato' }}>Manufacturing</span>
                        </div>
                          </Col>
                          <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '10px 0'}}>
                          <div onClick={() => ServiceAutomotive('Movers')} className='zoomEffect' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',padding: '15px 0', boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', borderRadius:'5px' }}>
                          <div style={{ height: '100px', width: '100px', backgroundColor: '#bbb', borderRadius: '50%', overflow: 'hidden' }}>
                                <img src={automobile} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div> 
                        <span style={{ marginTop: '10px',fontWeight: '700',fontFamily: 'Lato' }}>Movers</span>
                        </div>
                          </Col>
                          <Col md={4} xs={6} style={{textAlign: '-webkit-center',margin: '10px 0'}}>
                          <div onClick={() => Serviceecommers('E-Commerce')} className='zoomEffect' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',padding: '15px 0', boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', borderRadius:'5px' }}>
                          <div style={{ height: '100px', width: '100px', backgroundColor: '#bbb', borderRadius: '50%', overflow: 'hidden' }}>
                                <img src={Ecommers} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div> 
                        <span style={{ marginTop: '10px',fontWeight: '700',fontFamily: 'Lato' }}>E-Commerce</span>
                        </div>
                          </Col>
                        </Row>

                    </div>
                  </div>
              </Col>
             
            </Row>
          </div>
        </div>  
        </div> 
      </div>  
    </div>
  )
}

export default Selectserives


