import React, { useEffect, useState } from 'react'
import {Row,Col,Form} from 'react-bootstrap'
import dashboard from '../Images/signup_image.png';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';

const Payment = () => {

  const navigate = useNavigate()
  const planName = sessionStorage.getItem('planTitle')
  const planTax = parseFloat(sessionStorage.getItem('plantax')) || 0; 
  const myCompanay = sessionStorage.getItem('MyCompanay')
  const planAmount = parseFloat(sessionStorage.getItem('planAmount')) || 0; 
  const mycountry = sessionStorage.getItem('country')
  const subIDString = sessionStorage.getItem('subscriptionID')
  const subscriptionID = parseInt(subIDString, 10)
  const mycreditString = sessionStorage.getItem('credit');
  const mycredit = parseInt(mycreditString, 10); 
  const maintoken = sessionStorage.getItem('Token')
  const userId = sessionStorage.getItem('userId')

  const totalAmount = planAmount + planTax;

  const handleSubmit = async ()=>{

    const formData = {
      plantype:"Normal",
      userId:userId,
      subscriptionId: subscriptionID,
      provinceId: 2,
      longDistanceLeads: true,
      amount: totalAmount,
      credit:mycredit
    }


      try {
        const response = await fetch(`https://verse.blaccdot.com/ldsbackend/campaign/create`,{
          method:"POST",
          headers:{
            'Content-Type': 'application/json',
            'Authorization': `${maintoken}`
          },
          body:JSON.stringify(formData)
        })

        if(response.ok){
          const data = await response.json()
          toast.success('Payment successful!', {
             duration: 3000,
             position: 'top-right',
            style: { background: 'green', color: 'white' },
              });
              const paymentUrl = data?.data.url; 
              console.log('paymentUrl:', paymentUrl);
              sessionStorage.setItem('paymentUrl', paymentUrl);
              // window.location.href = '/thankyou';
         setTimeout(()=>{
          navigate('/thankyou')
        },3000)
        }else{
           const errorData = await response.json()
            toast.error(errorData.message || 'Signup failed. Please try again', {
              duration: 3000,
              position: 'top-right',
              style: {
                background: '#F4405F',
                color: 'white',
              },
            });
        }

      } catch (error) {
        toast.error('Something went wrong. Please try again.', {
         duration: 3000,
         position: 'top-right',
         style: {
           background: '#F4405F',
           color: 'white',
         },
       });
      }
  }



  return (
    <div>
       <Toaster/>
<Row>
  {/* Left Column (Sticky) */}
  <Col md={7}>
    <div style={{ backgroundColor: '#F7F9FC', position: 'sticky', top: '0', height: '100vh' }}>
      <div className="pt-5">
        <div style={styles.cardContainer}>
          <h5 style={styles.quote}>
            "Few things make me feel more powerful than setting up automations in Untitled to make my life easier and more efficient."
          </h5>
          <p style={styles.author}>
            — Aliah Lane <br />
            <span style={styles.position}>Founder, Layers.io</span>
          </p>
        </div>
      </div>
      <div style={{ textAlign: 'end' }}>
        <img
          style={{
            width: '90%',
            objectFit: 'cover',
            borderRadius: '20px 0px 0px 20px',
            border: '3px solid #757575',
            borderRight: 'none',
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
          }}
          src={dashboard}
          alt="dashboard"
        />
      </div>
    </div>
  </Col>

  {/* Right Column (Scrollable Content) */}
  <Col md={5} style={{ height: '100vh', overflowY: 'auto' }}>
    <div>
      <div className="signup-container">
        <div className="steps-navigation">
          <span className="step">
            <span className="circleProcess">1</span> Sign Up
          </span>
          <span className="separator" style={{ color: '#959595' }}>
            <ChevronRightIcon />
          </span>
          <span className="step" style={{ color: '#000' }}>
            <span className="circleProcess">2</span> Payment
          </span>
          <span className="separator" style={{ color: '#959595' }}>
            <ChevronRightIcon />
          </span>
          <span className="step" style={{ color: '#959595' }}>
            <span className="circleProcessnon">3</span> Access dashboard
          </span>
        </div>
        <div className="account-section">
          <h2>Ready to Launch?</h2>
          <p>One final step to unlock your dashboard and boost your business</p>
        </div>

        <div>
          <Form>
          <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
          <AccountBalanceWalletOutlinedIcon sx={{fontSize: '20px'}}/>
        </Typography>
        <h4>Confirm before payment</h4><hr/>
        <Row>
        <Col xs={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Plan type:</span>
                <span style={{ fontSize: '14px', textAlign: 'end' ,textTransform: 'uppercase'}}>{planName}</span>
            </div>
         </Col>
        <Col xs={12} className='mt-2'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Company Name:</span>
                <span style={{ fontSize: '14px', textAlign: 'end',textTransform: 'uppercase' }}>{myCompanay}</span>
            </div>
         </Col>
        <Col xs={12} className='mt-2'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Country:</span>
                <span style={{ fontSize: '14px', textAlign: 'end',textTransform: 'uppercase' }}>{mycountry}</span>
            </div>
         </Col>
        <Col xs={12} className='mt-2'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Amount:</span>
                <span style={{ fontSize: '14px', textAlign: 'end' }}>₹ {planAmount}</span>
            </div>
         </Col>
        <Col xs={12} className='mt-2'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Tax:</span>
                <span style={{ fontSize: '14px', textAlign: 'end' }}>₹ {planTax}</span>
            </div>
         </Col>
        <Col xs={12} className='mt-2'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Total amount:</span>
                <span style={{ fontSize: '14px', textAlign: 'end' }}>₹ {totalAmount}</span>
            </div>
         </Col><hr style={{marginTop:'20px'}} />

       <Button onClick={handleSubmit} sx={{width:'100%'}} variant="contained">Pay now</Button>

        </Row>
      </CardContent>
    </Card>
          </Form>
        </div>
      </div>
    </div>
  </Col>
</Row>


    </div>
  )
}

const styles = {
    cardContainer: {
      padding: '20px',
      borderRadius: '8px',
      maxWidth: '600px',
      margin: '0px auto',
      textAlign: 'start',
      fontFamily: 'Arial, sans-serif',
    },
    quote: {
      fontSize: '25px',
      color: '#333',
      marginBottom: '20px',
    },
    author: {
      fontSize: '16px',
      color: '#555',
      fontWeight: 'bold',
    },
    position: {
      fontSize: '14px',
      color: '#777',
    },
  };


export default Payment