import React, { useEffect, useState } from 'react';
import Headers from '../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import Hero from '../Images/hero.jpg';
import { Zoom, Fade } from 'react-reveal';
import TextField from '@mui/material/TextField';
import { IoChevronBackCircleOutline } from 'react-icons/io5';
import { toast, ToastContainer } from 'react-toastify'; // Import toast and container
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import { Bounce } from 'react-toastify'; // Import optional transition


const indiaStates = [
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
];

const canadaStates = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Nova Scotia',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Northwest Territories',
  'Nunavut',
  'Yukon',
  'Northwest Territories',
  'Nunavut',
  'Yukon',
];


const ukStates = [
  'London',
  'Birmingham',
  'Manchester',
  'Liverpool',
  'Leeds',
  'Glasgow',
  'Bristol',
  'Cardiff',
  'Edinburgh',
  'Nottingham',
]




const Finalform = () => {
  const navigate = useNavigate();
  const [noOfLeads, setNoOfLeads] = useState('');
  const [loading, setLoading] = useState(false);
  const [minDays, setMinDays] = useState('');
  const [maxDays, setMaxDays] = useState('');
  const [errors, setErrors] = useState({}); 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [region, setRegion] = useState('');
  const [myCountry, setCountry] = useState('');
  const [state, setState] = useState('');
  const [brand, setBrand] = useState('');
  const [websiteURL, setWebsiteURL] = useState('');
  const [noLead, setNoLead] = useState('');


  // const country = sessionStorage.getItem('selectedSubService');

  useEffect(() => {
    const storedCountry = sessionStorage.getItem('selectedSubService');
    if (storedCountry === 'India' || storedCountry === 'Canada' || storedCountry === 'United Kingdom') {
      setCountry(storedCountry);
    }else {
      setCountry('')
    }
  }, []);

  const indiaStateList = indiaStates;
  const canadaStateList = canadaStates;
  const ukStateList = ukStates;

  const getStateList = () => {
    if (myCountry === 'India') {
      return indiaStateList;
    } else if (myCountry === 'Canada') {
      return canadaStateList;
    } else if (myCountry === 'United Kingdom') {
      return ukStateList;
    } else {
      return [];
    }
  };

  const stateList = getStateList()


  const handleSubmit = async ()=>{
      setLoading(true)
    const payload = {
      name: name,
      email: email,
      number: number,
      country: myCountry, 
      state: state,
      city: region,
      brand_name: brand,
      url: websiteURL,
      lead_count: noOfLeads,
      no_of_leads: noOfLeads,
      min_days: maxDays,
      max_days: minDays
    }
    if (validateForm()) {
      sessionStorage.setItem('name', name);
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('number', number);
      sessionStorage.setItem('region', region);
      sessionStorage.setItem('country', myCountry);
      sessionStorage.setItem('state', state);
      sessionStorage.setItem('brand', brand)
      sessionStorage.setItem('websiteURL', websiteURL)
      sessionStorage.setItem('Lead', noLead)
      sessionStorage.setItem('noOfLeads', noOfLeads)
      sessionStorage.setItem('maxDays', maxDays)
      sessionStorage.setItem('minDays', minDays)


    try {
      const response = await fetch('https://euctostaging.com/lms/api/businessinitialform',{
        method:'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      const result = await response.json()

      if (result.id) {
        sessionStorage.setItem('finalFormID', result.id); // Save the ID
      }
      toast.success('Quort Submitted Successfully!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce,
      });
      setTimeout(()=>{
        window.location.replace('/subscription');
      },2000)
    
    } catch (error) {
      console.log('Error while fetching data', error)
    }finally{
      setLoading(false)
    }
  }
  }

//  validation Function
  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const contactRegex = /^\d{10}$/;
  
    if (!name.trim()) {
      errors.fullName = 'Full Name is required';
    }
  
    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!emailRegex.test(email)) {
      errors.email = 'Invalid email format';
    }
  
    if (!number.trim()) {
      errors.contactNo = 'Contact Number is required';
    } else if (!contactRegex.test(number)) {
      errors.contactNo = 'Kindly enter correct number';
    }
  
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
//  validation Function
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };
    if (name === 'fullName') {
      setName(value);
      delete newErrors['fullName'];
    } else if (name === 'email') {
      setEmail(value);
      delete newErrors['email'];
    } else if (name === 'contactNo') {
      setNumber(value);
      delete newErrors['contactNo'];
    }
    setErrors(newErrors);

  }
  // Select Lead count function
  const handleQueryChange = (e) => {
    const selectedOption = e.target.value;
    switch (selectedOption) {
      case '500':
        setNoOfLeads('500');
        setMinDays('30');
        setMaxDays('90');
        break;
      case '1000':
        setNoOfLeads('1000');
        setMinDays('30');
        setMaxDays('90');
        break;
      case '2000':
        setNoOfLeads('2000');
        setMinDays('30');
        setMaxDays('90');
        break;
      default:
    }
  };
  

  
  
  return (
  <div>
      <Headers />
      <ToastContainer />
      <div style={{backgroundColor:'#E6EDF3',height: '85vh', width: '100vw', margin: '0',padding: '0'}}>
      <div className="container py-3" >
        <div className="formBoxsh">
          <div className="container">
            <Row>
              <Col xs={5} style={{ padding: '0px', flex: '0 0 auto', width: '41.66666667%' }} className="FormImages">
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                  <img alt='imageRac' src={Hero} style={{ width: '95%', borderRadius: '10px 10px 10px 10px', marginTop: '10px', height: '445px' }} />
                </div>
              </Col>
              <Col xs={12} md={7} style={{ padding: '0px', position: 'relative' }}>
                <div className='mainTopic'
                  style={{
                    width: '55%',
                    height: '20%',
                    borderRadius: '0px 10px 10px 0px',
                    border: '7px solid #00A0E3',
                    marginTop: '10px',
                    fontWeight: '600',
                    backgroundColor: '#00A0E3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Zoom right>
                    <h5  style={{ color: '#ffff', fontFamily: 'Lato, sans-serif', margin: '0px', fontWeight: '600' }}>One step to get quality leads</h5>
                  </Zoom>
                </div>
                <Link to="/selectservices"><IoChevronBackCircleOutline className='backButton' size={40} style={{ position: 'absolute', right: '5%', top: '2%', color: 'red' }} /></Link>
                
                  <div className="calenderHeight" style={{ marginTop: '25px', display: 'flex', justifyContent: 'center' }}>
                    <div className="container">
                      {/* Personal Details */}
                      <Row>
                        <Col xs={12} md={4}>
                          <Form.Group>
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="fullName"
                              value={name}
                              onChange={handleChange}
                              isInvalid={!!errors.fullName} />
                            <Form.Control.Feedback type="invalid">{errors.fullName}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Label>Contact Number</Form.Label>
                          <Form.Control
                            type="type"
                            name="contactNo"
                            value={number}
                            onChange={handleChange}
                            isInvalid={!!errors.contactNo} />
                          <Form.Control.Feedback type="invalid">{errors.contactNo}</Form.Control.Feedback>
                        </Col>
                      </Row>

                      {/* Region area */}
      <Row className="mt-4">
        <Col xs={12} md={6}>
          <Form.Select
            name="myCountry"
            style={{ height: '55px' }}
            className="inputBoxes"
            value={myCountry}
            onChange={(e) => {
              const selectedCountry = e.target.value;
              setCountry(selectedCountry);  // Update selected country
              setState('');  // Clear the selected state
            }}
          >
            <option>--Select country--</option>
            {myCountry === '' && (
              <>
                <option value="India">India</option>
                <option value="Canada">Canada</option>
                <option value="United Kingdom">United Kingdom</option>
              </>
            )}
            {myCountry && <option value={myCountry}>{myCountry}</option>}
          </Form.Select> 
        </Col>
        <Col xs={12} md={6}>
          <Form.Select
            name="subCountry"
            style={{ height: '55px' }}
            className="inputBoxes"
            value={state}
            onChange={(e) => setState(e.target.value)}
            disabled={myCountry === ''} // Disable state dropdown if no country is selected
>
            <option disabled>--Select State--</option>
            {stateList.length === 0 ? (
              <option disabled>No states available</option>
            ) : (
              stateList.map((stateName, index) => (
                <option key={index} value={stateName}>
                  {stateName}
                </option>
              ))
            )}
          </Form.Select>
        </Col>
      </Row>

                      {/* Area Name */}
                      <Row className="mt-4">
                        <Col xs={12} md={4}>
                          <TextField  name="brandName" label="Brand Name" variant="outlined" style={{ width: '100%',  }} className='inputBoxes'  value={brand}  onChange={(e)=> setBrand(e.target.value)} />
                        </Col>
                        <Col xs={12} md={4}>
                          <TextField name="websiteURL" label="Website url" variant="outlined" style={{ width: '100%',}} className='inputBoxes'  value={websiteURL}  onChange={(e)=> setWebsiteURL(e.target.value)}  />
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Select style={{ height: '55px' }}  onChange={handleQueryChange}>
                            <option>No.of Queries</option>
                            {/* <option value="30">30</option> */}
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="2000">2000</option>
                          </Form.Select>
                        </Col>
                      </Row>
                      {/* No of lead */}
                      <Row className="mt-4">
                        <Col xs={12} md={4}>
                          <Form.Group>
                            <Form.Label>No. of Leads</Form.Label>
                            <Form.Control
                              type="text"
                              style={{ borderColor: '#C4C4C4', height: '45px', backgroundColor: '#ececec' }}
                              value={noOfLeads}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Group>
                            <Form.Label>Minimum Days</Form.Label>
                            <Form.Control
                              type="text"
                              style={{ borderColor: '#C4C4C4', height: '45px', backgroundColor: '#ececec' }}
                              value={minDays}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                          <Form.Group>
                            <Form.Label>Maximum Days</Form.Label>
                            <Form.Control
                              type="text"
                              style={{ borderColor: '#C4C4C4', height: '45px', backgroundColor: '#ececec' }}
                              value={maxDays}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px 0px 15px 0px' }}>
                        <button className='button-47' onClick={handleSubmit} disabled={loading}>
                          {loading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : (
                            <>PROCESS</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
            
              </Col>
            </Row>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Finalform;



// import React, { useState } from 'react';
// import Headers from '../components/Header';
// import { Link, useNavigate } from 'react-router-dom';
// import { Row, Col, Form } from 'react-bootstrap';
// import Hero from '../Images/hero.jpg';
// import { Zoom, Fade } from 'react-reveal';
// import TextField from '@mui/material/TextField';
// import { IoChevronBackCircleOutline } from 'react-icons/io5';
// import { toast, ToastContainer } from 'react-toastify'; // Import toast and container
// import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
// import { Bounce } from 'react-toastify'; // Import optional transition

// const Finalform = () => {
//   const navigate = useNavigate();
//   const [noOfLeads, setNoOfLeads] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [minDays, setMinDays] = useState('');
//   const [maxDays, setMaxDays] = useState('');
//   const [errors, setErrors] = useState({}); 
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [number, setNumber] = useState('');
//   const [region, setRegion] = useState('');
//   const [myCountry, setCountry] = useState('');
//   const [state, setState] = useState('');
//   const [brand, setBrand] = useState('');
//   const [websiteURL, setWebsiteURL] = useState('');
//   const [noLead, setNoLead] = useState('');
  

//   // const handleSubmit = () => {
//   //   if (validateForm()) {
//   //     sessionStorage.setItem('name', name);
//   //     sessionStorage.setItem('email', email);
//   //     sessionStorage.setItem('number', number);
//   //     sessionStorage.setItem('region', region);
//   //     sessionStorage.setItem('country', country);
//   //     sessionStorage.setItem('panIndia', panIndia);
//   //     sessionStorage.setItem('brand', brand)
//   //     sessionStorage.setItem('websiteURL', websiteURL)
//   //     sessionStorage.setItem('Lead', noLead)
//   //     sessionStorage.setItem('noOfLeads', noOfLeads)
//   //     sessionStorage.setItem('maxDays', maxDays)
//   //     sessionStorage.setItem('minDays', minDays)
//   //     navigate('/subscription');
//   //   }
//   // };

//   const handleSubmit = async ()=>{
//     const payload = {
//       name: name,
//       email: email,
//       number: number,
//       country: myCountry, 
//       state: state,
//       city: region,
//       brand_name: brand,
//       url: websiteURL,
//       lead_count: noOfLeads,
//       no_of_leads: noOfLeads,
//       min_days: maxDays,
//       max_days: minDays
//     }
//     if (validateForm()) {
//       console.log('Payload before POST:', payload); // Debug here
//       sessionStorage.setItem('name', name);
//       sessionStorage.setItem('email', email);
//       sessionStorage.setItem('number', number);
//       sessionStorage.setItem('region', region);
//       sessionStorage.setItem('country', myCountry);
//       sessionStorage.setItem('state', state);
//       sessionStorage.setItem('brand', brand)
//       sessionStorage.setItem('websiteURL', websiteURL)
//       sessionStorage.setItem('Lead', noLead)
//       sessionStorage.setItem('noOfLeads', noOfLeads)
//       sessionStorage.setItem('maxDays', maxDays)
//       sessionStorage.setItem('minDays', minDays)


//     try {
//       const response = await fetch('https://euctostaging.com/lms/api/businessinitialform',{
//         method:'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(payload)
//       });

//       const result = response.json()
//       console.log('API Response:', result);
//       toast.success('Quort Submitted Successfully!', {
//         position: 'top-right',
//         autoClose: 2000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: 'dark',
//         transition: Bounce,
//       });
//       setTimeout(()=>{
//         window.location.replace('/subscription');
//       },2000)
    
//     } catch (error) {
//       console.log('Error while fetching data', error)
//     }
//   }
//   }


// //  validation Function
//   const validateForm = () => {
//     const errors = {};
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const contactRegex = /^\d{10}$/;
  
//     if (!name.trim()) {
//       errors.fullName = 'Full Name is required';
//     }
  
//     if (!email.trim()) {
//       errors.email = 'Email is required';
//     } else if (!emailRegex.test(email)) {
//       errors.email = 'Invalid email format';
//     }
  
//     if (!number.trim()) {
//       errors.contactNo = 'Contact Number is required';
//     } else if (!contactRegex.test(number)) {
//       errors.contactNo = 'Kindly enter correct number';
//     }
  
//     setErrors(errors);
//     return Object.keys(errors).length === 0;
//   };
  
// //  validation Function
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     const newErrors = { ...errors };
//     if (name === 'fullName') {
//       setName(value);
//       delete newErrors['fullName'];
//     } else if (name === 'email') {
//       setEmail(value);
//       delete newErrors['email'];
//     } else if (name === 'contactNo') {
//       setNumber(value);
//       delete newErrors['contactNo'];
//     }
//     setErrors(newErrors);
//   };

//   // Select Lead count function
//   const handleQueryChange = (e) => {
//     const selectedOption = e.target.value;
//     switch (selectedOption) {
//       case '500':
//         setNoOfLeads('500');
//         setMinDays('30');
//         setMaxDays('90');
//         break;
//       case '1000':
//         setNoOfLeads('1000');
//         setMinDays('30');
//         setMaxDays('90');
//         break;
//       case '2000':
//         setNoOfLeads('2000');
//         setMinDays('30');
//         setMaxDays('90');
//         break;
//       default:
//     }
//   };
  

  
  
//   return (
//   <div>
//       <Headers />
//       <ToastContainer />
//       <div style={{backgroundColor:'#E6EDF3',height: '85vh', width: '100vw', margin: '0',padding: '0'}}>
//       <div className="container py-3" >
//         <div className="formBoxsh">
//           <div className="container">
//             <Row>
//               <Col xs={5} style={{ padding: '0px', flex: '0 0 auto', width: '41.66666667%' }} className="FormImages">
//                 <div style={{ textAlign: 'center', marginBottom: '10px' }}>
//                   <img alt='imageRac' src={Hero} style={{ width: '95%', borderRadius: '10px 10px 10px 10px', marginTop: '10px', height: '445px' }} />
//                 </div>
//               </Col>
//               <Col xs={12} md={7} style={{ padding: '0px', position: 'relative' }}>
//                 <div className='mainTopic'
//                   style={{
//                     width: '55%',
//                     height: '20%',
//                     borderRadius: '0px 10px 10px 0px',
//                     border: '7px solid #00A0E3',
//                     marginTop: '10px',
//                     fontWeight: '600',
//                     backgroundColor: '#00A0E3',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                   }}>
//                   <Zoom right>
//                     <h5  style={{ color: '#ffff', fontFamily: 'Lato, sans-serif', margin: '0px', fontWeight: '600' }}>One step to get quality leads</h5>
//                   </Zoom>
//                 </div>
//                 <Link to="/selectservices"><IoChevronBackCircleOutline className='backButton' size={40} style={{ position: 'absolute', right: '5%', top: '2%', color: 'red' }} /></Link>
                
//                   <div className="calenderHeight" style={{ marginTop: '25px', display: 'flex', justifyContent: 'center' }}>
//                     <div className="container">
//                       {/* Personal Details */}
//                       <Row>
//                         <Col xs={12} md={4}>
//                           <Form.Group>
//                             <Form.Label>Full Name</Form.Label>
//                             <Form.Control
//                               type="text"
//                               name="fullName"
//                               value={name}
//                               onChange={handleChange}
//                               isInvalid={!!errors.fullName} />
//                             <Form.Control.Feedback type="invalid">{errors.fullName}</Form.Control.Feedback>
//                           </Form.Group>
//                         </Col>
//                         <Col xs={12} md={4}>
//                           <Form.Label>Email</Form.Label>
//                           <Form.Control
//                             type="email"
//                             name="email"
//                             onChange={handleChange}
//                             isInvalid={!!errors.email}
//                           />
//                           <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
//                         </Col>
//                         <Col xs={12} md={4}>
//                           <Form.Label>Contact Number</Form.Label>
//                           <Form.Control
//                             type="type"
//                             name="contactNo"
//                             value={number}
//                             onChange={handleChange}
//                             isInvalid={!!errors.contactNo} />
//                           <Form.Control.Feedback type="invalid">{errors.contactNo}</Form.Control.Feedback>
//                         </Col>
//                       </Row>
//                       {/* Region area */}
//                       <Row className="mt-4">
//                         <Col xs={12} md={4}>
//                         <Form.Select name="myCountry" style={{ height: '55px' }} className="inputBoxes" value={myCountry} 
//                          onChange={(e) => setCountry(e.target.value)} >
//                             <option disabled>--Select country --</option>
//                             <option value="India">India</option>
//                             <option value="United Kingdom">United Kingdom</option>
//                             <option value="Canada">Canada</option>
//                           </Form.Select>
//                         </Col>
//                         <Col xs={12} md={4}>
//                           <Form.Select name="subCountry" style={{ height: '55px' }} className='inputBoxes' value={state}  onChange={(e)=> setState(e.target.value)}>
//                             <option disabled>--Select State--</option>
//                             <option value="Andhra Pradesh">Andhra Pradesh</option>
//                             <option value="Arunachal Pradesh">Arunachal Pradesh</option>
//                             <option value="Assam">Assam</option>
//                             <option value="Bihar">Bihar</option>
//                             <option value="Chhattisgarh">Chhattisgarh</option>
//                             <option value="Goa">Goa</option>
//                             <option value="Gujarat">Gujarat</option>
//                             <option value="Haryana">Haryana</option>
//                             <option value="Himachal Pradesh">Himachal Pradesh</option>
//                             <option value="Jammu and Kashmir">Jammu and Kashmir</option>
//                             <option value="Tamil Nadu">Tamil Nadu</option>
//                             <option value="Kerala">Kerala</option>
//                             <option value="Punjab">Punjab</option>
//                           </Form.Select>
//                         </Col>
//                         <Col xs={12} md={4}>
//                           <TextField name="region" style={{ height: '55px' }} className='inputBoxes'  label="Enter City" 
//                            value={region}  onChange={(e)=> setRegion(e.target.value)} />
//                         </Col>
//                       </Row>
//                       {/* Area Name */}
//                       <Row className="mt-4">
//                         <Col xs={12} md={4}>
//                           <TextField  name="brandName" label="Brand Name" variant="outlined" style={{ width: '100%',  }} className='inputBoxes'  value={brand}  onChange={(e)=> setBrand(e.target.value)} />
//                         </Col>
//                         <Col xs={12} md={4}>
//                           <TextField name="websiteURL" label="Website url" variant="outlined" style={{ width: '100%',}} className='inputBoxes'  value={websiteURL}  onChange={(e)=> setWebsiteURL(e.target.value)}  />
//                         </Col>
//                         <Col xs={12} md={4}>
//                           <Form.Select style={{ height: '55px' }}  onChange={handleQueryChange}>
//                             <option>No.of Queries</option>
//                             {/* <option value="30">30</option> */}
//                             <option value="500">500</option>
//                             <option value="1000">1000</option>
//                             <option value="2000">2000</option>
//                           </Form.Select>
//                         </Col>
//                       </Row>
//                       {/* No of lead */}
//                       <Row className="mt-4">
//                         <Col xs={12} md={4}>
//                           <Form.Group>
//                             <Form.Label>No. of Leads</Form.Label>
//                             <Form.Control
//                               type="text"
//                               style={{ borderColor: '#C4C4C4', height: '45px', backgroundColor: '#ececec' }}
//                               value={noOfLeads}
//                               readOnly
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col xs={12} md={4}>
//                           <Form.Group>
//                             <Form.Label>Minimum Days</Form.Label>
//                             <Form.Control
//                               type="text"
//                               style={{ borderColor: '#C4C4C4', height: '45px', backgroundColor: '#ececec' }}
//                               value={minDays}
//                               readOnly
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col xs={12} md={4}>
//                           <Form.Group>
//                             <Form.Label>Maximum Days</Form.Label>
//                             <Form.Control
//                               type="text"
//                               style={{ borderColor: '#C4C4C4', height: '45px', backgroundColor: '#ececec' }}
//                               value={maxDays}
//                               readOnly
//                             />
//                           </Form.Group>
//                         </Col>
//                       </Row>
//                       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '15px 0px 15px 0px' }}>
//                         <button className='button-47' onClick={handleSubmit} disabled={loading}>
//                           {loading ? (
//                             <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
//                           ) : (
//                             <>PROCESS</>
//                           )}
//                         </button>
//                       </div>
//                     </div>
//                   </div>
            
//               </Col>
//             </Row>
//           </div>
//         </div>
//       </div>
//       </div>
//     </div>
//   );
// };

// export default Finalform;



